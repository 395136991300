export function getCurrencySymbol(currency = '', useSymbol = true, useSpace = false): string {
  let currencySymbol = currency.trim()
  const currencySymbols: Record<string, string> = {
    USD: '$', // 美元
    EUR: '€', // 欧元
    CNY: '¥', // 人民币
    HKD: 'HK$', // 港币
    SGD: 'S$', // 新加坡币
    // VND: '₫', // 越南盾
    // 更多币种和符号可以在这里添加
  }

  if (useSymbol) {
    currencySymbol = currencySymbols[currency.trim()] || currency
  }

  if (useSpace) {
    currencySymbol = `${currencySymbol} `
  }

  // 返回对应的币种符号，如果币种未知，则返回币种名称
  return currencySymbol
}
